import * as React from "react";

import Layout from "../components/Layout"
import Seo from "../components/Seo"

import PageTitle from '../components/PageTitle';
import TitleSection from "../components/TitleSection";
import Prices from "../components/Prices";
import {StaticImage} from "gatsby-plugin-image";

const Pricelist = () => (
  <Layout>
    <Seo title="Pricelist" />
    <PageTitle title={'Pricelist'}/>

    <TitleSection title={"Our Pricelist"}/>

    {/* Full width image */}
    <div className={'relative max-w-6xl mx-auto px-4 sm:px-6'} data-aos={"fade"}>
      <div className={`w-full mb-12`}>
        <StaticImage src={"../images/pricelist.jpg"} alt={'Adrian Rowe Haircutters'}/>
      </div>
    </div>

    <Prices/>

  </Layout>
)

export default Pricelist;
