import * as React from "react";

const Prices = () => {

  const womensPrices = [
    { service: 'Wet Cut', price: '29.00' },
    { service: 'Wash, Cut, Blowdry', price: '43.95' },
    { service: 'Blowdry', price: '23.00' },
  ];

  const womensColourTreatments = [
    { service: 'Partial Foils', price: '43.00' },
    { service: 'Half Head Foils', price: '59.00' },
    { service: 'Full Head Foils', price: '85.00' },
    { service: 'Root Colour', price: '39.00' },
    { service: 'Full Head Colour', price: '59.00' },
  ];

  const womensStyling = [
    { service: 'Perm', price: '54.95' }
  ];

  const mensPrices = [
    {
      service: 'Gents Cut',
      price: '23.00'
    }
  ];

  const childsPrices = [
    {
      service: 'Cut',
      price: '21.95'
    }
  ];


  return (
    <section>
      <div className={`max-w-6xl mx-auto px-6 md:px-8`}>

        <div className={`grid md:grid-cols-2 gap-24 pb-12`}>
          <div data-aos={"fade"}>
            <h2 className={`text-black font-bold text-3xl uppercase mb-6`}>Women<span className={`text-red`}>.</span></h2>

            <div className={`relative grid grid-cols-2 gap-y-3 mb-6`}>

              <span className={`absolute -top-6 right-1 text-gray-600 uppercase text-sm`}>From</span>

              { womensPrices.map((item) => (
                <>
                  <p className={`font-light font-lg uppercase`}>{ item.service }</p>
                  <span className={`text-right`}>£{ item.price }</span>
                </>
              ))}
            </div>

            <h3 className={`text-black font-bold text-xl uppercase mb-6`}>Colour Treatments</h3>
            <div className={`relative grid grid-cols-2 gap-y-3 mb-6`}>
              <span className={`absolute -top-6 right-1 text-gray-600 uppercase text-sm`}>From</span>

              { womensColourTreatments.map((item) => (
                <>
                  <p className={`font-light font-lg uppercase`}>{ item.service }</p>
                  <span className={`text-right`}>£{ item.price }</span>
                </>
              ))}
            </div>

            <h3 className={`text-black font-bold text-xl uppercase mb-6`}>Styling</h3>

            <div className={`relative grid grid-cols-2 gap-y-3 mb-6`}>
              <span className={`absolute -top-6 right-1 text-gray-600 uppercase text-sm`}>From</span>

              { womensStyling.map((item) => (
                <>
                  <p className={`font-light font-lg uppercase`}>{ item.service }</p>
                  <span className={`text-right`}>£{ item.price }</span>
                </>
              ))}
            </div>

          </div>


          <div data-aos={"fade"} data-aos-delay={"400"}>
            <h2 className={`text-black font-bold text-3xl uppercase mb-6`}>Men<span className={`text-red`}>.</span></h2>

            <div className={`relative grid grid-cols-2 gap-y-3 mb-20`}>
              <span className={`absolute -top-6 right-1 text-gray-600 uppercase text-sm`}>From</span>

              { mensPrices.map((item) => (
                <>
                  <p className={`font-light font-lg uppercase`}>{ item.service }</p>
                  <span className={`text-right`}>£{ item.price }</span>
                </>
              ))}
            </div>


            <h2 className={`text-black font-bold text-3xl uppercase mb-6`}>Children<span className={`text-red`}>.</span></h2>

            <div className={`relative grid grid-cols-2 gap-y-3 mb-6`}>
              <span className={`absolute -top-6 right-1 text-gray-600 uppercase text-sm `}>From</span>

              { childsPrices.map((item) => (
                <>
                  <p className={`font-light font-lg uppercase`}>{ item.service }</p>
                  <span className={`text-right`}>£{ item.price }</span>
                </>
              ))}
            </div>

          </div>

        </div>

      </div>
    </section>
  )

}

export default Prices;